
import { defineComponent } from "vue";
import { contentsService, StaticContent } from "@/services/contents.service";
import PageHeadline from "@/common/PageHeadline.vue";
import HomeOurPeople from "@/modules/our-team/HomeOurPeople.vue";
import { setPageDescription, setPageTitle } from "@/common/meta.utils";
import LaunchYourProduct from "@/components/LaunchYourProduct.vue";
import Thumb from "@/common/Thumb.vue";

export default defineComponent({
  name: "OurTeam",
  components: { Thumb, LaunchYourProduct, HomeOurPeople, PageHeadline },
  data() {
    return {
      page: undefined as StaticContent | undefined,
      localCharities: undefined as StaticContent | undefined,
      timeTogether: undefined as StaticContent | undefined,
      listPage: undefined as StaticContent | undefined,
      flipList: [] as any[][],
    };
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      contentsService.findBySlug("page-our-team").then((page) => {
        this.page = page;
        setPageTitle(page?.title);
        setPageDescription(page?.lead);
      });

      contentsService.findBySlug("our-team-local-charities").then((page) => {
        this.localCharities = page;
      });
      contentsService.findBySlug("our-team-time-together").then((page) => {
        this.timeTogether = page;
      });
    },
  },
  watch: {},
});
